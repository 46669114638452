
.ant-message {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none;
  }
  .ant-message-notice {
    padding: 8px;
    text-align: center;
  }
  .ant-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
            box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
  }
  .ant-message-success .anticon {
    color: #52c41a;
  }
  .ant-message-error .anticon {
    color: #ff4d4f;
  }
  .ant-message-warning .anticon {
    color: #faad14;
  }
  .ant-message-info .anticon,
  .ant-message-loading .anticon {
    color: #1890ff;
  }
  .ant-message .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px;
  }
  .ant-message-notice.move-up-leave.move-up-leave-active {
    -webkit-animation-name: MessageMoveOut;
            animation-name: MessageMoveOut;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }

@-webkit-keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .ant-message-rtl {
    direction: rtl;
  }
  .ant-message-rtl span {
    direction: rtl;
  }
  .ant-message-rtl .anticon {
    margin-right: 0;
    margin-left: 8px;
  }
  